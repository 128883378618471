export const menu = {
  Sales: [
    "Brokerage",
    "Alcar Boats",
    "Alcar Boats - Classic",
    "Alcar Boats - Fisherman",
    "Dinghies",
    "Evinrude Outboard Motors",
    "Sunfish",
    "Fiberglass Parts",
    "Marine Supplies",
    "Parts",
    "Venture Trailers",
  ],
  Services: ["Boat Hauling", "Boat Storage", "Moorings", "Service"],
  Rentals: ["Alcar Powerboat", "Sunfish", "Rhodes 19"],
  "Tide Calendars": ["Download"],
  "Pump Out": [
    "Alcar Environmental",
    "Clean Vessel Act",
    "Pumpout Boats",
    "Pumpout Boats in Use",
  ],
  About: [
    "News",
    //  "History",
    "Directions",
    "Contact",
  ],
  "Spring Launch": ["Schedule"],
}
