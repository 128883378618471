import React from "react"
import { Container, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Boat from "../assets/boat.svg"

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    borderTop: `1px solid ${theme.palette.divider}`,
    boxSizing: `border-box`,
    color: `#ffffff`,
    padding: `2rem 5vw 2rem 5vw`,
    marginTop: "30vh",
    minHeight: "20vh",
  },
}))

export const Footer = () => {
  const classes = useStyles()

  return (
    <Container component="footer" maxWidth={false} className={classes.footer}>
      <Boat
        style={{
          display: "block",
          margin: "-16vh auto 16vh auto",
          width: "199px",
          maxWidth: "30vw",
          height: "auto",
        }}
      />
      <Grid container spacing={2} alignContent="center" justify="space-evenly">
        <Grid item xs={12} style={{ marginTop: "20vh" }}>
          <Typography
            variant="caption"
            align="center"
            display="block"
            paragraph
          >
            2568 Rt 6 Wellfleet, MA 02667 | Phone: 508-349-3840
          </Typography>
          <Typography variant="caption" align="center" display="block">
            &copy; {new Date().getFullYear()} Bay Sails Marine, Inc
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
