// REACT
import React from "react"
// PROP TYPES
import PropTypes from "prop-types"
// REACT HELMET
import Helmet from "react-helmet"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// MUI
import { CssBaseline, Paper } from "@material-ui/core"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
// COMPONENTS
import { Header } from "./header"
import { Footer } from "./footer"

// THEME
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#315db6",
    },
    secondary: {
      main: "#5db631",
    },
    background: {
      default: "#91abff",
    },
  },
})

// FLUID IMAGE FRAGMENT
export const query = graphql`
  fragment BsmFluidImage on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

// COMPONENT FUNCTION
const Layout = ({ description, lang, meta, title, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  // COMPONENT
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header siteTitle={site.siteMetadata.title} />
        <Paper
          elevation={3}
          style={{
            margin: `0 auto`,
            maxWidth: "960px",
            padding: `2rem`,
          }}
        >
          <main>{children}</main>
        </Paper>
        <Footer />
      </ThemeProvider>
    </>
  )
}

// PROP TYPES
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

Layout.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default Layout
