// REACT
import React, { useState } from "react"
// MUI
import { makeStyles } from "@material-ui/core/styles"
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Collapse,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
// GATSBY
import { Link } from "gatsby"
// CLSX
import clsx from "clsx"
// MENU
import { menu } from "./menu"
// IMAGES
import Banner from "../assets/bsm-banner.svg"

// STYLES
const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  banner: {
    maxWidth: theme.spacing(48),
    height: "auto",
    width: "90%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "block",
  },
}))

// COMPONENT FUNCTION
export const Header = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // STATE
  const [state, setState] = useState({
    open: false,
    nested: [false, false, false, false, false, false, false, false],
  })

  // REMOVE 'SPRING LAUNCH' FROM MENU
  const today = new Date()
  const thisMonth = today.getMonth() + 1
  if (thisMonth < 2 || thisMonth > 5) {
    delete menu["Spring Launch"]
  }

  // HANDLERS
  const handleDrawerOpen = () => {
    setState(prev => ({ ...prev, open: true }))
  }

  const handleDrawerClose = () => {
    setState(prev => ({ ...prev, open: false }))
  }

  const handleClick = index => {
    const newNested = state.nested
    newNested[index] = !newNested[index]
    setState(prev => ({ ...prev, nested: newNested }))
  }

  // COMPONENT
  return (
    <header>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: state.open,
          })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              <Link to="/">
                <Banner className={classes.banner} />
              </Link>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: state.open,
          })}
        >
          <div className={classes.drawerHeader} />
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {Object.keys(menu).map((text, index) => (
              <div key={text}>
                <ListItem button onClick={() => handleClick(index)}>
                  <ListItemText primary={text} />
                  {state.nested[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={state.nested[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menu[text].map(option => {
                      let optionText = ""
                      switch (text) {
                        case "Rentals":
                          optionText = "Rentals - " + option
                          break
                        case "Tide Calendars":
                          optionText = "Tide Calendars"
                          break
                        case "Spring Launch":
                          optionText = "Spring Launch"
                          break
                        default:
                          optionText = option
                      }

                      return (
                        <ListItem
                          key={option}
                          button
                          component="a"
                          href={
                            "/" +
                            optionText
                              .toLowerCase()
                              .replace(" - ", " ")
                              .replace(/\s/g, "-")
                          }
                          className={classes.nested}
                        >
                          <ListItemText primary={option} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
        </Drawer>
      </div>
    </header>
  )
}
